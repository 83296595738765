html {
  height: auto;
  min-height: 100%;
}
body {
  height: auto;
  min-height: 100%;
  font-stretch: condensed;
  min-width: 300px;
}
.ant-menu {
  &.ant-menu-dark {
    .ant-menu-item-selected {
      background-color: #77215e;
    }
  }
}

.ant-layout-sider {
  background: rgba(71, 40, 81, 1);
  background: -moz-linear-gradient(top, rgba(71, 40, 81, 1) 0%, rgba(121, 6, 83, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(71, 40, 81, 1)),
    color-stop(100%, rgba(121, 6, 83, 1))
  );
  background: -webkit-linear-gradient(top, rgba(71, 40, 81, 1) 0%, rgba(121, 6, 83, 1) 100%);
  background: -o-linear-gradient(top, rgba(71, 40, 81, 1) 0%, rgba(121, 6, 83, 1) 100%);
  background: -ms-linear-gradient(top, rgba(71, 40, 81, 1) 0%, rgba(121, 6, 83, 1) 100%);
  background: linear-gradient(to bottom, rgba(71, 40, 81, 1) 0%, rgba(121, 6, 833, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#472851', endColorstr='#790653', GradientType=0 );
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub:not(.ant-menu-submenu-hidden) {
  background: transparent;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: #472851 !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #472851), color-stop(100%, #790653)) !important;
  background: linear-gradient(to bottom, #472851 0%, #790653 100%) !important;
}
.ant-layout-sider-trigger {
  background: transparent;
}

#nprogress {
  .bar {
    background: #a6d52d;
    .peg {
      box-shadow: 0 0 10px #a6d52d, 0 0 5px #a6d52d;
    }
  }

  .spinner-icon {
    border-top-color: #77215e;
    border-left-color: #77215e;
  }
}

.btn.btn-primary,
.show > .btn.btn-primary {
  background-color: #77215e;
  border-color: #77215e;
}

.ant-dropdown-menu-item {
  > a {
    &:hover {
      color: #77215e;
    }
  }
}

.dark-theme {
  background: #472851 !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #472851), color-stop(100%, #790653)) !important;
  background: linear-gradient(to bottom, #472851 0%, #790653 100%) !important;

  color: #fff;

  .ant-layout-header button {
    color: #fff !important;
  }

  a:hover {
    color: #fff;
  }

  .website-header-topbar,
  .ant-layout {
    background: transparent !important;
  }
}

.light-color {
  .ant-layout-content {
    background: #f5f3f9;
  }
}
.center-content {
  .utils__content {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.action-button {
  display: block;
  width: 100%;
  border-radius: 50px;
  padding: 0px 30px;
  height: 50px;
  line-height: 50px;
  background: #8ecf25;
  border: none;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2) !important;
  transition: all ease-in-out 600ms;

  &.outlined {
    background: none;
    border: 2px solid #8ecf25 !important;
    color: #8ecf25;
    box-shadow: none !important;
  }

  &:hover,
  &:focus {
    color: #8ecf25 !important;
    background: #fff !important;
  }
}

.users-layout {
  .utils__content {
    overflow: visible;
    padding-left: rem(30);
    padding-right: rem(30);
    max-width: 768px;
    margin: 0 auto;
  }
}

.user-webmenu {
  .ant-drawer-body {
    padding: 0;
  }
  .menu-content {
    padding: 20px;
  }
  .top {
    padding: 0 1.53rem;
    min-height: 64px;
    height: 64px;
    text-align: right;
    .ant-btn {
      // background: none !important;
      // box-shadow: none !important;
      // outline: none !important;
      border: none;
      color: #333;
      font-size: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 50px;
      height: 50px;
      line-height: 30px;
      padding: 0;
      text-align: center;
    }
  }
  .welcome {
    border-bottom: 1px solid #efedf3;
    color: #afadba;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    h3 {
      color: #555;
      margin: 0;
      padding: 0;
      font-size: 22px;
      line-height: 30px;
      text-transform: none;
    }
    p {
      color: #ccc;
    }
  }
  .main-navigation {
    list-style: none;
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid #efedf3;
    .menuTitle {
      color: #77215e !important;
      font-size: 18px;
    }
    .infoText {
      color: #666;
      font-size: 15px;
      margin-bottom: 10px;
    }
    li {
      padding: 5px 0;
      button,
      a {
        color: #333 !important;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        background: none !important;
        box-shadow: none !important;
        border: none !important;
        outline: 0 !important;
      }
    }
  }
  .secondary-navigation {
    list-style: none;
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid #efedf3;
    li {
      padding: 5px 0;
      button,
      a {
        color: #918e9f !important;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        border: none;
      }
    }
  }

  .signOut {
    text-align: center;
    .ant-btn {
      border: none !important;
      box-shadow: none !important;
      outline: 0 !important;
      font-weight: bold;
      color: #333;
      font-size: 15px;
      line-height: 24px;
    }
  }
}

.user-profilemenu {
  .ant-drawer-body {
    //max-width: 768px;
    margin: 0 auto;
    padding: 0;
  }
  .profile-data {
    padding: 0 20px;
    max-width: 768px;
    margin: 0 auto;
  }

  .userDetails {
    text-align: center;
    h3 {
      color: #333;
      text-align: center;
      font-size: 22px;
      line-height: 24px;
      margin: 0;
    }
    p {
      color: #9d9ba9;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      margin: 0;
    }
    .avatarWrap {
      position: relative;
      width: 100px;
      margin: 0 auto 20px auto;
      .imgWrap {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        border: 2px solid #fff;
        img {
          width: 100%;
        }
      }
      .badge {
        position: absolute;
        bottom: 0;
        right: 0;
        background: #790653;
        color: #fff;
        width: 40px;
        height: 40px;
        line-height: 29px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: normal;
        border: 2px solid #fff;
      }
    }

    .ant-row {
      margin-top: 30px;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
    .livestatus {
      text-align: center;
      color: #9d9ba9;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      margin: 0;
      span {
        color: #333;
        font-size: 26px;
        line-height: 32px;
        display: block;
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }
  .profileTabs {
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-left: -20px;
      margin-right: -20px;
    }
    .ant-tabs-bar {
      margin: 0;
      .ant-tabs-nav-scroll {
        text-align: center;

        .ant-tabs-nav {
          display: block;
          > div {
            display: flex;
            align-items: center;
            justify-content: space-around;
          }

          .ant-tabs-tab {
            display: block;
            width: 100%;
            margin: 0;
            font-weight: bold;
            text-transform: uppercase;
            padding: 15px 20px;
          }
        }
      }
    }

    .ant-tabs-tabpane {
      padding: 20px 30px;
      background: #f5f3f9;
      min-height: 400px;
    }
  }

  .adsList {
    .block {
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
      background: #fff;
      display: block;
      width: 100%;

      img {
        width: 100%;
        display: block;
      }
      > div {
        background: #fff;
        padding: 10px 20px;
      }
      h3 {
        font-size: 12px;
        line-height: 18px;
        margin: 0;
        padding: 0;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        color: #333;
        font-size: 10px;
        line-height: 12px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

// Style your items
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  // background: #d1cfcf;
  //aspect-ratio: 1;
  margin-bottom: 15px;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: transparent !important;
}

.highlight {
  font-weight: bold;
  color: #77215e;
}

.slick-slider {
  .slick-arrow {
    font-size: 0px !important;
    &:before {
      font-size: 60px !important;
    }
  }
}

.zoom-and-crop {
  > .placeholder {
    height: 300px;
  }
}

.dropzone {
  min-height: 200px;
  width: 100%;
  border: 1px dashed #472851;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0 !important;

  &.dragactive {
    background: #efefef;
  }

  &.error {
    background: #efefef;
    border: 1px dashed #ff0000 !important;
  }

  > div {
    padding: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    outline: 0;
  }
  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }

  padding: 10px 10px !important;
  height: auto !important;

  .img-thumbnail {
    height: 58px !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid #424242 !important;
  border-radius: 5px !important;
  background: #fff !important;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
  }

  .dz-error-mark {
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      width: 28px;
      height: 28px;
    }
  }

  .dz-success-mark {
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      width: 28px;
      height: 28px;
    }
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    top: 60px;

    &:after {
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}

img {
  max-width: 100%;
}

/* NEW 2021 */
@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}
@for $i from 1 through 10 {
  .w#{$i*10} {
    width: $i * 10px !important;
  }
  .h#{$i*10} {
    height: $i * 10px !important;
  }
  .wp#{$i*10} {
    width: $i * 10% !important;
  }
}
@for $i from 3 through 10 {
  .w#{$i*50} {
    width: $i * 50px !important;
  }
  .h#{$i*50} {
    height: $i * 50px !important;
  }
}
@for $i from 1 through 20 {
  .wh#{$i*10} {
    width: $i * 10px !important;
    height: $i * 10px !important;
  }
  .m#{$i*5} {
    margin: $i * 5px !important;
  }
  .ml#{$i*5} {
    margin-left: $i * 5px !important;
  }
  .mr#{$i*5} {
    margin-right: $i * 5px !important;
  }
  .mt#{$i*5} {
    margin-top: $i * 5px !important;
  }
  .mb#{$i*5} {
    margin-bottom: $i * 5px !important;
  }
  .p#{$i*5} {
    padding: $i * 5px !important;
  }
  .pl#{$i*5} {
    padding-left: $i * 5px !important;
  }
  .pr#{$i*5} {
    padding-right: $i * 5px !important;
  }
  .pt#{$i*5} {
    padding-top: $i * 5px !important;
  }
  .pb#{$i*5} {
    padding-bottom: $i * 5px !important;
  }
}
.white {
  color: $white;
}
.green {
  color: $slime-green;
}

.width-full {
  width: 100% !important;
}
.height-full {
  height: 100% !important;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.tright {
  text-align: right;
}
.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.uppercase,
.up {
  text-transform: uppercase;
}
.space-center {
  display: flex;
  align-items: center;
  justify-content: center;
  * + * {
    margin-left: 10px;
  }
}
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.space-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  * + * {
    margin-left: 10px;
  }
}
.space-between,
.space-evenly,
.flex-right {
  @media (max-width: 700px) {
    flex-direction: column;
    * + * {
      margin-top: 10px;
    }
  }
}
.block {
  display: block !important;
}
.hidden {
  display: none !important;
}
a {
  display: inline-block;
}
// a:hover {
//   color: #659714 !important;
// }
// a:focus {
//   color: #3f6108 !important;
// }
a.underline {
  display: inline-block;
  text-decoration: underline;
}
a.underline:hover {
  text-decoration: none;
  //color: $slime-green !important;
}
.ant-form {
  width: 100%;
}
.ant-form-item-label {
  // display: none;
  color: #fff !important;
  padding-bottom: 1px !important;
}
.dark label {
  color: #fff !important;
}
.hr {
  height: 1px;
  background-color: #707070;
}
.ant-form-item-required::before {
  color: #fff;
}
.ant-form-item {
  margin-bottom: 0 !important;
}

input[type='text'],
input[type='password'] {
  border-radius: 0;
  padding: 10px;
  font-size: 14px;
  // line-height: 32px;
  height: auto;
  border: none;
  margin: 4px 0 0;
}
.input1 {
  border: 1px solid #c9c9c9 !important;
}
// .ant-checkbox-inner {
//   width: 35px !important;
//   height: 35px !important;
//   background-color: #fff !important;
// }

// .ant-checkbox-checked .ant-checkbox-inner::after {
//   width: 8px !important;
//   height: 19px !important;
//   left: 26% !important;
//   top: 44% !important;
//   border-color: #472851 !important;
// }

.form-actions {
  margin: 30px 0 0 0;
  border: none;
  padding: 30px 20px;

  @media (max-width: 768px) {
    margin: 0px 0 0 0;
    padding: 20px 20px;
  }
}
.ant-btn-link {
  color: #101820;
}
.ant-btn-primary,
.ant-btn-danger {
  display: inline-block;
  border-radius: 0;
  padding: 10px 50px;
  border: none !important;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  height: auto;
  width: auto;
  clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 20px);

  // box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4) !important;
  font-size: 18px;
}
.ant-btn-primary {
  background: #8ecf25;
}
.ant-btn-danger {
  background: #77215e;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background: #681550;
}
.ant-btn-sm {
  font-size: 15px;
}
.ant-btn-sm2 {
  font-size: 11px !important;
  padding: 5px 10px;
  clip-path: polygon(6px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 6px);
}
.ant-btn-primary:hover {
  color: #fff;
  background-color: #659714;
}
.ant-btn-primary:focus {
  color: #fff;
  background-color: #3f6108;
}
a.ant-btn {
  padding-top: 10px !important;
}
.ant-select-selection {
  box-shadow: none !important;
  border: solid 1px #c9c9c9 !important;
}
.ant-modal-content {
  border-radius: 0;
}
.ant-modal-body {
  padding: 0;
}
.ant-checkbox-disabled + span {
  color: unset;
}
.title1 {
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #fff;
  margin-bottom: 40px;
  @media (max-width: $md-max-width) {
    font-size: 20px;
  }
}
.title1_5 {
  font-size: 30px;
}
.title2 {
  font-size: 25px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a1b61;
}
.title3 {
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: #8a1b61;
}

.link1 {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.19px;
}

.link2 {
  font-size: 16px;
  text-decoration: underline;

  letter-spacing: 0.15px;
  line-height: 24px;
}
a.white {
  color: #fff;
  &:hover {
    color: #ccc;
  }
}
.info1 {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.label1 {
  font-family: RobotoCondensedBold;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.13px;
  color: #8a1b61;
  margin-bottom: 4px;
}

.oval-img {
  border: 1px solid #ccc;
  border-radius: 50%;
  //padding: 1px;
  img {
    border-radius: 50%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    vertical-align: unset;
  }
  img.cover {
    object-fit: cover;
  }
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset !important;
  background-color: #c9c9c9 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2b4d1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8a1b61;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e0734;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6e73775e;
  z-index: 9999999;
  font-size: 36px;
  color: #472851;
}
.overlay.fixed {
  position: fixed;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin purchasedTriangle($size) {
  content: '';
  position: absolute;
  bottom: $size/3;
  right: $size/3;
  display: block;
  width: $size;
  height: $size;
  //background: linear-gradient(135deg, transparent 51%, $primary 51%);
  border-radius: 50%;
  background-color: $primary;
}

.purchased-triangle-small:after {
  @include purchasedTriangle(15px);
}
.purchased-triangle:after {
  @include purchasedTriangle(30px);
}

.infoBox {
  font-size: 9px;
  font-weight: 300;
  line-height: 1.22;
  letter-spacing: 0.08px;
  background-color: #ececec;
  border: solid 1px #707070;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  svg {
    margin-right: 5px;
    color: $primary;
  }
}

.dark-dialog {
  .ant-modal-content {
    background-color: #000000aa;
    border: 3px solid #fff;
  }
  .ant-modal-confirm-content {
    color: #fff;
    font-size: 16px;
  }
  .ant-modal-confirm-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    float: none;
    flex-direction: row-reverse;
    * + * {
      margin-right: 10px;
    }
    .ant-btn {
      padding: 7px 30px;
    }
    @media (max-width: $md-max-width) {
      flex-direction: column-reverse;
      margin-bottom: 20px;
      * + * {
        margin-bottom: 20px !important;
      }
      .ant-btn {
        width: 60%;
      }
    }
  }
}

@media (max-width: $md-max-width) {
  .ant-btn-primary,
  .ant-btn-danger {
    padding: 4px 10px;
    font-size: 14px;
    clip-path: polygon(12px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 12px);
  }
  .ant-btn-sm {
    font-size: 11px;
  }
  .ant-btn-sm2 {
    font-size: 10px !important;
    padding: 1px 5px;
  }
}
